import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useForm } from 'react-hook-form';
import Filter from '../../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import TextField from '../../../components/TextField';
import DateInput from '../../../components/DateInput';
import SelectOptions from '../../../components/SelectOptions';
import Modal from '../../../components/Modal/Modal';
import { translateMessage } from '../../../utils/functions/translateMessage';
import Button from '../../../components/Button/Button';
import Information from '../../../components/Information/Information';
import { ImSearch } from 'react-icons/im';
import { LoadingContainer } from '../../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@material-ui/core';
import Table from '../../../components/Table/Table';
import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import formatMoney from '../../../utils/functions/formatMoneyBRL';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import {
  pixTransactionsFetchAction,
  pixTransactionDebitDetailsFetchAction,
  pixTransactionCreditDetailsFetchAction,
} from '../../../store/fetchActions/pixTransactionsActions';
import { ConvertObjectToArrayCreditDetails } from './ConvertObjectsToArray';
import StatusContainer from '../../../components/StatusContainer/StatusContainer';
import { useTheme } from 'styled-components';
import moment from 'moment';
import InformationPix from '../components/InformationPix';

const PixInfractions = () => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [isModalInfoOpen, setIsModalInfoOpen] = useState(false);
  const [idTypeTransaction, setIdTypeTransaction] = useState(null);
  const [idTransaction, setIdTransaction] = useState(null);
  const [filterData, setFilterData] = useState('');
  const [filter, setFilter] = useState({
    SEARCH: '',
    LIMIT: 10,
    PAGE: 1,
    TIPO_TRANSACAO: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    STATUS_TRANSACAO: '',
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control } = useForm({});

  const {
    dataPixInfraction,
    loadingPixInfraction,
    errorLoadingPixInfraction,
    paginationPixInfraction,
    errorMessagePixInfraction,
    detailsPixInfraction,
    loadingDetailsPixInfraction,
    successDetailsPixInfraction,
    messageDetailsPixInfraction,
  } = useSelector((state) => state.pixInfraction);

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  const closeModalInfo = () => {
    setIsModalInfoOpen(false);
  };

  useCallFilter(filterData, setFilter, pixTransactionsFetchAction, 0, 0);

  useEffect(() => {
    dispatch(pixTransactionsFetchAction(filter));
  }, [filter.PAGE]);

  const arrayTransactionTypes = [
    { ID: 0, NOME: 'Todos' },
    { ID: 1, NOME: 'Pagamento' },
    { ID: 2, NOME: 'Recebimento' },
  ];

  const arrayStatus = [
    { ID: 0, NOME: 'Todos' },
    { ID: 1, NOME: 'Aguardando' },
    { ID: 2, NOME: 'Aprovado' },
    { ID: 3, NOME: 'Cancelado' },
  ];

  const pageOptions = [
    { ID: 10, NOME: 10 },
    { ID: 25, NOME: 25 },
    { ID: 50, NOME: 50 },
    { ID: 100, NOME: 100 },
  ];

  const detailsArray = ConvertObjectToArrayCreditDetails(detailsPixInfraction, idTypeTransaction);

  const header = [
    {
      id: 'data de registro',
      name: 'DATA',
      align: 'center',
    },
    {
      id: 'cpf/cnpj',
      name: 'CPF/CNPJ',
      align: 'left',
    },
    {
      id: 'nome',
      name: 'NOME',
      align: 'left',
    },
    {
      id: 'tipo transação',
      name: 'TIPO TRANSAÇÂO',
      align: 'center',
    },
    {
      id: 'status',
      name: 'STATUS TRANSAÇÂO',
      align: 'center',
    },
    {
      id: 'valor',
      name: 'VALOR',
      align: 'right',
    },
  ];

  const body = dataPixInfraction?.map((transaction) => {
    return {
      data: [
        { info: transaction.DATA_TRANSACAO, align: 'center' },
        {
          info: transaction.CPFCNPJ?.length > 11 ? formatCNPJ(transaction.CPFCNPJ) : formatCPF(transaction.CPFCNPJ),
          align: 'left',
        },
        { info: transaction.NOME, align: 'left' },
        { info: transaction.TIPO_TRANSACAO_PIX, align: 'center' },
        {
          info: transaction?.IDSTATUS ? (
            <StatusContainer
              code={transaction?.IDSTATUS}
              description={transaction?.STATUS === 'Confirmado' ? 'Aprovado' : transaction.STATUS}
            ></StatusContainer>
          ) : (
            '-----'
          ),
          align: 'center',
        },
        { info: 'R$ ' + formatMoney(transaction.VALOR), align: 'right' },
      ],
      onClick: () => inspectTransaction(transaction),
    };
  });

  function inspectTransaction(transaction) {
    if (transaction?.TIPO_TRANSACAO_PIX === 'RECEBIMENTO') return;

    const { IDTIPOTRANSACAO, ID } = transaction;
    setIdTypeTransaction(IDTIPOTRANSACAO);
    setIdTransaction(ID);

    if (IDTIPOTRANSACAO == 1) dispatch(pixTransactionDebitDetailsFetchAction({ ID_TRANSACAO: ID }));
    if (IDTIPOTRANSACAO == 2) dispatch(pixTransactionCreditDetailsFetchAction({ ID_TRANSACAO: ID }));

    setIsModalInfoOpen(true);
  }

  return (
    <>
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              <TextField label="Nome ou CPF/CNPJ" name={'SEARCH'} width={'25.042vw'} control={control} />
              <SelectOptions
                control={control}
                options={arrayTransactionTypes}
                name={'TIPO_TRANSACAO'}
                width={'10vw'}
                label={'Tipo Transação'}
                initialValue={0}
              />
              <SelectOptions
                label={'Status Transação'}
                width={'10vw'}
                name={'STATUS_TRANSACAO'}
                control={control}
                options={arrayStatus}
                initialValue={'0'}
              />
              <DateInput
                label="Data Inicial"
                name={'DATA_INICIAL'}
                control={control}
                value={dateInicial}
                width={'10vw'}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'DATA_FINAL'}
                control={control}
                value={dateFinal}
                width={'10vw'}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />
              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width={'16.042vw'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por Página</h5>
              <SelectOptions control={control} options={pageOptions} name={'LIMIT'} initialValue={10} />
            </SecondFilterLine>
          </form>
        </Filter>
        {loadingPixInfraction ? (
          <LoadingContainer>
            <CircularProgress size={'5vw'} color="inherit" />
          </LoadingContainer>
        ) : (
          <Table
            loading={loadingPixInfraction}
            error={errorLoadingPixInfraction}
            hasPagination={true}
            filter={filter}
            pagination={paginationPixInfraction}
            setFilter={setFilter}
            errorMessage={errorMessagePixInfraction}
            filterTable={filterTable}
            body={body}
            header={header}
            limit={filter.LIMIT}
          />
        )}
        <Modal open={isModalInfoOpen} close={closeModalInfo} title="Dados da Transação PIX" width={'47.889vw'}>
          {loadingDetailsPixInfraction ? (
            <LoadingContainer marginTop={'1vw'}>
              <CircularProgress size={'5vw'} color="inherit" />
            </LoadingContainer>
          ) : !successDetailsPixInfraction ? (
            <h1>{translateMessage(messageDetailsPixInfraction)}</h1>
          ) : (
            <InformationPix
              data={detailsArray}
              idTransaction={idTransaction}
              showButton={detailsPixInfraction.IDSTATUS === 1 ? true : false}
              onClose={closeModalInfo}
            />
          )}
        </Modal>
      </div>
    </>
  );
};

export default PixInfractions;
