import api from '../../utils/services/api';

import { pixAntiFraudActionLoading, pixAntiFraudActionSuccess, pixAntiFraudActionError } from '../ducks/pixAntiFraud';

export const pixAntiFraudFetchActions = (filter = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(pixAntiFraudActionLoading());

    try {
      const response = await api.get(`admin/pix/listar/anti-fraude`, filter);
      dispatch(pixAntiFraudActionSuccess(response));
    } catch (error) {
      dispatch(pixAntiFraudActionError(error?.response?.data));
    }
  };
};
