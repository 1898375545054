import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPixInfraction: [],
  errorPixInfraction: false,
  errorLoadingPixInfraction: false,
  errorMessagePixInfraction: '',
  loadingPixInfraction: false,
  paginationPixInfraction: {},
  detailsPixInfraction: {},
  loadingDetailsPixInfraction: false,
  messageDetailsPixInfraction: '',
  successDetailsPixInfraction: false,
};

export const pixInfractionActionLoading = createAction('PIX_INFRACTION_ACTION_LOADING');
export const pixInfractionActionSuccess = createAction('PIX_INFRACTION_ACTION_SUCCESS');
export const pixInfractionActionError = createAction('PIX_INFRACTION_ACTION_ERROR');

export const detailsPixInfractionActionLoading = createAction('DETAILS_PIX_INFRACTION_ACTION_LOADING');
export const detailsPixInfractionActionSuccess = createAction('DETAILS_PIX_INFRACTION_ACTION_SUCCESS');
export const detailsPixInfractionActionError = createAction('DETAILS_PIX_INFRACTION_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pixInfractionActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPixInfraction: true,
      errorPixInfraction: false,
    };
  },
  [pixInfractionActionSuccess.type]: (state, payload) => {
    const { data, pagination } = payload?.payload?.data?.data;

    return {
      ...state,
      loadingPixInfraction: false,
      errorPixInfraction: false,
      dataPixInfraction: data,
      paginationPixInfraction: pagination,
    };
  },
  [pixInfractionActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingPixInfraction: false,
      errorPixInfraction: true,
      errorMessagePixInfraction: payload?.mensagem,
      dataPixInfraction: [],
      paginationPixInfraction: {},
    };
  },
  [detailsPixInfractionActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailsPixInfraction: true,
    };
  },
  [detailsPixInfractionActionSuccess.type]: (state, payload) => {
    const { data, mensagem } = payload?.payload?.data;

    return {
      ...state,
      loadingDetailsPixInfraction: false,
      detailsPixInfraction: data,
      messageDetailsPixInfraction: mensagem,
      successDetailsPixInfraction: true,
    };
  },
  [detailsPixInfractionActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDetailsPixInfraction: false,
      detailsPixInfraction: [],
      messageDetailsPixInfraction: payload?.mensagem,
      successDetailsPixInfraction: false,
    };
  },
});
