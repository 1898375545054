import api from '../../utils/services/api';

import { pixInfractionActionLoading, pixInfractionActionSuccess, pixInfractionActionError } from '../ducks/pixInfraction';

export const pixInfractionFetchActions = (filter = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(pixInfractionActionLoading());

    try {
      const response = await api.post(`admin/pix/listar/infracoes`, filter);
      dispatch(pixInfractionActionSuccess(response));
    } catch (error) {
      dispatch(pixInfractionActionError(error?.response?.data));
    }
  };
};
