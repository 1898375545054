import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Filter from '../../../components/Filter/Filter';
import { FirstFilterLine, SecondFilterLine } from '../../../components/Filter/Filter.styles';
import TextField from '../../../components/TextField';
import DateInput from '../../../components/DateInput';
import InputMask from '../../../components/InputMask';
import SelectOptions from '../../../components/SelectOptions';
import Button from '../../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import { LoadingContainer } from '../../GerenciarClientes/styles/GerenciarClientes.styles';
import { CircularProgress } from '@material-ui/core';
import Table from '../../../components/Table/Table';
import formatCPF from '../../../utils/functions/formatCPF';
import formatCNPJ from '../../../utils/functions/formatCNPJ';
import useCallFilter from '../../../utils/hooks/useCallFilter';
import { pixKeysFetchAction } from '../../../store/fetchActions/pixKeysActions';
import { useTheme } from 'styled-components';
import moment from 'moment';

const PixRefund = () => {
  const theme = useTheme();

  const [filterData, setFilterData] = useState('');
  const [filter, setFilter] = useState({
    search: '',
    limit: 10,
    page: 1,
    dataInicial: '',
    dataFinal: '',
    chave: '',
    tipoChave: '',
    conta: '',
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control } = useForm({});

  const { 
    dataPixRefund,
    loadingPixRefund,
    errorLoadingPixRefund,
    paginationPixRefund,
    errorMessagePixRefund,
    detailsPixRefund,
    loadingDetailsPixRefund,
    successDetailsPixRefund,
    messageDetailsPixRefund,
  } = useSelector((state) => state.pixRefund);

  useCallFilter(filterData, setFilter, pixKeysFetchAction, 0, 0);

  const filterTable = (data) => {
    data.dataInicial = dateInicial;
    data.dataFinal = dateFinal;
    setFilterData(data);
  };

  const arrayKindOfKeys = [
    { ID: 'CPF', NOME: 'CPF' },
    { ID: 'Telefone', NOME: 'Telefone' },
    { ID: 'Email', NOME: 'Email' },
    { ID: 'Chave Aleatória', NOME: 'Chave Aleatória' },
  ];

  const pageOptions = [
    { ID: 25, NOME: 25 },
    { ID: 50, NOME: 50 },
    { ID: 100, NOME: 100 },
  ];

  const body = dataPixRefund?.map((key) => {
    return {
      data: [
        { info: key.DATA_INSERCAO, align: 'center' },
        { info: key.NOME, align: 'left' },
        {
          info: key.CPFCNPJ?.length > 11 ? formatCNPJ(key.CPFCNPJ) : formatCPF(key.CPFCNPJ),
          align: 'left',
        },
        { info: key.CHAVE, align: 'left' },
        { info: key.TIPO_CHAVE, align: 'center' },
        { info: key.AGENCIA, align: 'center' },
        { info: key.CONTA, align: 'center' },
      ],
    };
  });

  const header = [
    {
      id: 'data de registro',
      name: 'DATA INSERÇÂO',
      align: 'center',
    },
    {
      id: 'nome',
      name: 'CLIENTE',
      align: 'left',
    },
    {
      id: 'cpf/cnpj',
      name: 'CPF/CNPJ',
      align: 'left',
    },
    {
      id: 'chave',
      name: 'CHAVE',
      align: 'left',
    },
    {
      id: 'tipo chave',
      name: 'TIPO CHAVE',
      align: 'center',
    },
    {
      id: 'agencia',
      name: 'AGÊNCIA',
      align: 'center',
    },
    {
      id: 'conta',
      name: 'CONTA',
      align: 'center',
    },
  ];

  return (
    <>
      <div>
        <Filter>
          <form onSubmit={handleSubmit(filterTable)}>
            <FirstFilterLine>
              <TextField label="Nome ou CPF/CNPJ" name={'search'} width={'25.042vw'} control={control} />
              <TextField label="Chave" name={'chave'} width={'25.042vw'} control={control} />
              <SelectOptions
                label="Tipo de Chave"
                name={'tipoChave'}
                width={'15.042vw'}
                control={control}
                options={arrayKindOfKeys}
                initialValue={0}
              />
              <InputMask label="Conta" name="conta" mask="999999999999999" width={'25.042vw'} control={control} />
            </FirstFilterLine>
            <FirstFilterLine style={{ justifyContent: 'flex-start' }}>
              <DateInput
                label="Data Inicial"
                name={'dataInicial'}
                control={control}
                value={dateInicial}
                onChange={(newValue) =>
                  newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
                }
              />
              <DateInput
                label="Data Final"
                name={'dataFinal'}
                control={control}
                value={dateFinal}
                onChange={(newValue) =>
                  newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
                }
              />

              <Button
                backgroundcolor={theme.colors.buttonSearch}
                width={'16.042vw'}
                hoverbgcolor={theme.colors.buttonSearchHover}
                hovercolor={theme.colors.buttonSearchHoverText}
                mycolor={theme.colors.buttonSearchText}
                type="submit"
              >
                <ImSearch className="default-svg" size={'1.181vw'} />
                <span>Buscar</span>
              </Button>
            </FirstFilterLine>
            <SecondFilterLine>
              <h5>Resultados por Página</h5>
              <SelectOptions control={control} options={pageOptions} name={'limit'} initialValue={10} />
              <h5>Total de Registros: {paginationPixRefund?.totalResult ?? 0}</h5>
            </SecondFilterLine>
          </form>
        </Filter>
        {loadingPixRefund ? (
          <LoadingContainer>
            <CircularProgress size={'5vw'} color="inherit" />
          </LoadingContainer>
        ) : (
          <Table
            loading={loadingPixRefund}
            error={errorLoadingPixRefund}
            hasPagination={true}
            filter={filter}
            pagination={paginationPixRefund}
            setFilter={setFilter}
            errorMessage={errorMessagePixRefund}
            filterTable={filterTable}
            body={body}
            header={header}
            limit={filter.limit}
          />
        )}
      </div>
    </>
  );
};

export default PixRefund;
