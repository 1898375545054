import { useDispatch, useSelector } from 'react-redux';
import { useForm } from 'react-hook-form';
import Modal from '../../../../components/Modal/Modal';
import { SectionButton } from '../../styles/GerenciarContas.styles';
import Button from '../../../../components/Button/Button';
import SelectOptions from '../../../../components/SelectOptions';
import { useMemo, useState } from 'react';
import InputFormatValue from '../../../../components/InputFormatValue';
import { showAlert } from '../../../../store/ducks/sweetAlert';
import * as S from './ManageBalance.styles';
import { useEffect } from 'react';
import {
  tariffsCreditFetchAction,
  tariffsDebitFetchAction,
  tariffsFetchAction,
} from '../../../../store/fetchActions/tariffsActions';
import { fetchTypes } from '../../../../store/fetchActions/spreadTypeActions';
import {
  performCreditTransaction,
  performDebitTransaction,
} from '../../../../store/fetchActions/spreadTransactionActions';
import TextField from '../../../../components/TextField';
import { useTheme } from 'styled-components';
import formatMoney from '../../../../utils/functions/formatMoneyBRL';
import InputFormat from '../InputFormat';

const ManageBalance = ({ openModal, closeModal, idConta }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [type, setType] = useState();
  const [idTarifa, setIDTarifa] = useState();
  const [idSpreadType, setIDSpreadType] = useState();

  const submitTariffs = (data) => {
    const params = {
      ID_CONTA: idConta,
      ID_TARIFA: data.taxas_tarifa,
      VALOR: data.valor.replace('R$', '').replace(/\s/g, ''),
      ID_COBAN: usersData?.ID_COBAN,
      DESC_TARIFA: data.descricao,
    };

    if (data.valor === undefined || data.valor === 'R$ 0,00' || data.valor === '0.00') {
      dispatch(
        showAlert({
          title: 'Atenção!',
          text: 'O valor informado , deverá ser maior que 0!',
          icon: 'warning',
        }),
      );
    } else {
      type === 'CREDITAR' && dispatch(tariffsCreditFetchAction(params, closeModal, usersData.CPFCNPJ));
      type === 'DEBITAR' && dispatch(tariffsDebitFetchAction(params, closeModal, usersData.CPFCNPJ));
    }
  };

  const submitSpread = (data) => {
    const params = {
      ID_CONTA: idConta,
      ID_TARIFA: data.taxas_tarifa,
      VALOR: data.valor.replace('R$', '').replace(/\s/g, ''),
      ID_COBAN: usersData?.ID_COBAN,
      DESC_TARIFA: data.descricao,
      ID_TIPO_VALOR: idSpreadType,
      SPREAD: data.valor_spread.replace(/[R$%]/g, '').replace(/,/g, '.').trim(),
    };
    if (data.valor === undefined || data.valor === 'R$ 0,00' || data.valor === '0.00') {
      dispatch(
        showAlert({
          title: 'Atenção!',
          text: 'O valor informado , deverá ser maior que 0!',
          icon: 'warning',
        }),
      );
    } else {
      type === 'CREDITAR' && dispatch(performCreditTransaction(params, closeModal, usersData.CPFCNPJ));
      type === 'DEBITAR' && dispatch(performDebitTransaction(params, closeModal, usersData.CPFCNPJ));
    }
  };

  const submitForm = (data) => {
    if (idTarifa === 13 || idTarifa === 14) {
      submitSpread(data);
    } else {
      submitTariffs(data);
    }
  };

  const { detailAccountBalanceData, detailAccountsData } = useSelector((state) => state.account);
  const { loading } = useSelector((state) => state.spreadTransaction);

  const { usersData, usersBalance } = useSelector((state) => state.user);
  const { tariffsData } = useSelector((selector) => selector.tariffs);
  const { typesData } = useSelector((state) => state.spreadType);

  const { handleSubmit: handleBlockUser, control: controlBlockUser, setValue } = useForm({});

  useEffect(() => {
    dispatch(tariffsFetchAction(usersData?.ID_COBAN));
  }, [usersData?.ID_COBAN]);

  const tarifas = useMemo(() => {
    return tariffsData?.map((val) => {
      return { ID: val.ID, NOME: val.DESCRICAO };
    });
  }, [tariffsData]);

  useMemo(() => {
    return tariffsData?.filter((val) => {
      if (val.ID == idTarifa && (idTarifa === 13 || idTarifa === 14)) {
        setValue('valor_spread', val.VALOR);
        setValue('tipo_spread', val.ID_TIPO_VALOR);
        setIDSpreadType(val.ID_TIPO_VALOR);
      } else {
        setValue('valor', val.VALOR);
      }
    });
  }, [idTarifa]);

  useEffect(() => {
    dispatch(fetchTypes());
  }, [dispatch]);

  const spreads = useMemo(() => {
    return typesData?.map((val) => {
      return { ID: val.ID, DESC: val.DESCRICAO, NOME: val.TIPO };
    });
  }, [typesData]);

  return (
    <Modal open={openModal} close={closeModal} title={'Gerenciar Histórico'} width={'40vw'}>
      <S.SubTitle>
        <h5>Conta: {detailAccountsData?.conta?.CONTA}</h5>
        <h5>Saldo atual: R$ {formatMoney(detailAccountBalanceData?.saldo, 4)} </h5>
      </S.SubTitle>
      <form onSubmit={handleBlockUser(submitForm)}>
        <S.FormSectionInput>
          <S.ConteinerInput>
            <SelectOptions
              control={controlBlockUser}
              options={tarifas}
              name={'taxas_tarifa'}
              width={'100%'}
              label={'Taxas/ Tarifas'}
              required={true}
              onClick={(e) => setIDTarifa(e?.ID)}
            />
          </S.ConteinerInput>
          {(idTarifa === 13 || idTarifa === 14) && (
            <S.ConteinerInput>
              <SelectOptions
                control={controlBlockUser}
                options={spreads}
                name={'tipo_spread'}
                width={'30%'}
                label={'Tipo'}
                required={true}
                displayRow={true}
                onClick={(e) => setIDSpreadType(e?.ID)}
              />
              <InputFormat
                control={controlBlockUser}
                formatType={idSpreadType === 1 ? 'currency' : idSpreadType === 2 ? 'percent' : undefined}
                label={'Valor do Spread'}
                width={'70%'}
                name={'valor_spread'}
                required={true}
                decimalPlaces={4}
              />
            </S.ConteinerInput>
          )}
          <S.ConteinerInput>
            <InputFormat
              control={controlBlockUser}
              formatType={'currency'}
              label={'Valor'}
              width={'100%'}
              name={'valor'}
              required={true}
              decimalPlaces={4}
            />
          </S.ConteinerInput>
          <S.ConteinerInput>
            <TextField name={'descricao'} label={'Descrição'} fullWidth multiline={true} control={controlBlockUser} />
          </S.ConteinerInput>
        </S.FormSectionInput>
        <SectionButton>
          <Button
            backgroundcolor={theme.colors.veryDarkDesaturatedBlue}
            width={'16.792vw'}
            height={'3.125vw'}
            hoverbgcolor={theme.colors.veryDarkDesaturatedBlue}
            hovercolor={theme.colors.white}
            mycolor={theme.colors.white}
            type={'submit'}
            disabled={loading}
            onClick={() => setType('DEBITAR')}
          >
            <span>DEBITAR (RETIRAR)</span>
          </Button>
          <Button
            backgroundcolor={theme.colors.darklimeGreen}
            width={'16.792vw'}
            height={'3.125vw'}
            hoverbgcolor={theme.colors.darklimeGreen}
            hovercolor={theme.colors.white}
            mycolor={theme.colors.white}
            type={'submit'}
            disabled={loading}
            onClick={() => setType('CREDITAR')}
          >
            <span>CREDITAR (ADICIONAR)</span>
          </Button>
        </SectionButton>
      </form>
    </Modal>
  );
};

export default ManageBalance;
