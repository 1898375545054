import React, { useState, useEffect } from 'react';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import PropTypes from 'prop-types';
import { useNavigate, useLocation } from 'react-router-dom';
import { BiTransfer } from 'react-icons/bi';
import { MdOutlineAdminPanelSettings, MdOutlinePayments } from 'react-icons/md';
import { VscHome } from 'react-icons/vsc';
import { useIcon } from '../../../utils/hooks/useIcon';
import {
  GerenciarContas,
  Logs,
  Relatorio,
  GerenciarClientes,
  GerenciarScrows,
  TransacaoBoleto,
  ServicosDigitais,
  Pix,
  Notificacao,
  TransferenciaTed,
  BarrConfig,
  Antifraude,
} from '../../../assets/icon';
import * as Styled from '../../Tabs/Tabs.styles';
import { useTheme } from 'styled-components';

export default function ListItemSidebar(props) {
  const { classes } = props;
  const navigate = useNavigate();
  const location = useLocation();
  const [selectedTab, setSelectedTab] = useState('/dashboard');
  const { menu, isLoading } = useIcon();
  const theme = useTheme();

  useEffect(() => {
    setSelectedTab(location.pathname);
  }, [location]);

  const icons = {
    GerenciarClientes: <GerenciarClientes />,
    GerenciarContas: <GerenciarContas />,
    GerenciarScrows: <GerenciarScrows />,
    BiTransfer: <BiTransfer />,
    TransferenciaTed: <TransferenciaTed />,
    Pix: <Pix />,
    PixAdmin: <MdOutlineAdminPanelSettings />,
    Pagamentos: <MdOutlinePayments />,
    Relatorio: <Relatorio />,
    Logs: <Logs />,
    Notificacao: <Notificacao />,
    BarrConfig: <BarrConfig />,
  };

  return !isLoading ? (
    <List>
      <ListItem
        selected={selectedTab === '/dashboard' || selectedTab === '/'}
        button
        onClick={() => navigate('/dashboard')}
        key={'menus' + 0}
      >
        <ListItemIcon className={classes.icons}>
          <VscHome />
        </ListItemIcon>
        <ListItemText primary="Dashboard" />
      </ListItem>

      {menu.map((val, indx) => {
        return (
          <ListItem
            selected={selectedTab === val.navigate}
            button
            onClick={() => navigate(val.navigate)}
            key={'menus' + (indx + 1)}
          >
            <ListItemIcon className={classes.icons}>{icons[`${val.icon}`]}</ListItemIcon>
            <ListItemText primary={val.name} />
            {val.badge && typeof props[`${val.badge}`] !== 'object' ? (
              <Styled.UserNotificationContainer>
                <Styled.Badge color={theme.colors.brown} badgeContent={props ? props[`${val.badge}`] : null}></Styled.Badge>
              </Styled.UserNotificationContainer>
            ) : null}
            {typeof props[`${val.badge}`] === 'object' ? (
              <Styled.UserNotificationDiv>
                <Styled.UserNotificationContainer>
                  <Styled.Badge color="green" badgeContent={props ? props[`${val.badge}`].credito : null}></Styled.Badge>
                </Styled.UserNotificationContainer>
                <Styled.UserNotificationContainer>
                  <Styled.Badge color="red" badgeContent={props ? props[`${val.badge}`].debito : null}></Styled.Badge>
                </Styled.UserNotificationContainer>
              </Styled.UserNotificationDiv>
            ) : null}
          </ListItem>
        );
      })}
    </List>
  ) : null;
}

ListItemSidebar.propTypes = {
  text: PropTypes.string,
  index: PropTypes.number,
  classes: PropTypes.object,
};
