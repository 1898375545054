import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPixRefund: [],
  errorPixRefund: false,
  errorLoadingPixRefund: false,
  errorMessagePixRefund: '',
  loadingPixRefund: false,
  paginationPixRefund: {},
  detailsPixRefund: {},
  loadingDetailsPixRefund: false,
  messageDetailsPixRefund: '',
  successDetailsPixRefund: false,
};

export const pixRefundActionLoading = createAction('PIX_REFUND_ACTION_LOADING');
export const pixRefundActionSuccess = createAction('PIX_REFUND_ACTION_SUCCESS');
export const pixRefundActionError = createAction('PIX_REFUND_ACTION_ERROR');

export const detailsPixRefundActionLoading = createAction('DETAILS_PIX_REFUND_ACTION_LOADING');
export const detailsPixRefundActionSuccess = createAction('DETAILS_PIX_REFUND_ACTION_SUCCESS');
export const detailsPixRefundActionError = createAction('DETAILS_PIX_REFUND_ACTION_ERROR')

export default createReducer(INITIAL_STATE, {
  [pixRefundActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPixRefund: true,
      errorPixRefund: false,
    };
  },
  [pixRefundActionSuccess.type]: (state, payload) => {
    const { data, pagination } = payload?.payload?.data?.data;

    return {
      ...state,
      loadingPixRefund: false,
      errorPixRefund: false,
      dataPixRefund: data,
      paginationPixRefund: pagination,
    };
  },
  [pixRefundActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingPixRefund: false,
      errorPixRefund: true,
      errorMessagePixRefund: payload?.mensagem,
      dataPixRefund: [],
      paginationPixRefund: {},
    };
  },
  [detailsPixRefundActionLoading.type]: (state) => {
    return {
      ...state,
      loadingDetailsPixRefund: true,
    };
  },
  [detailsPixRefundActionSuccess.type]: (state, payload) => {
    const { data, mensagem } = payload?.payload?.data;

    return {
      ...state,
      loadingDetailsPixRefund: false,
      detailsPixRefund: data,
      messageDetailsPixRefund: mensagem,
      successDetailsPixRefund: true,
    };
  },
  [detailsPixRefundActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDetailsPixRefund: false,
      detailsPixRefund: [],
      messageDetailsPixRefund: payload?.mensagem,
      successDetailsPixRefund: false,
    };
  },
});
