import Modal from '../Modal/Modal';
import Button from '../Button/Button';
import { useForm } from 'react-hook-form';
import { MdErrorOutline } from 'react-icons/md';
import * as Styled from './RenovacaoJwt.styles';
import { useDispatch } from 'react-redux';
import { renovationJwtAction } from '../../store/fetchActions/loginActions';
import TextField from '../TextField';
import { useTheme } from 'styled-components';

const RenovacaoJwt = ({ open, close, time }) => {
  const theme = useTheme();
  const dispatch = useDispatch();

  const { handleSubmit: handleUserPassword, control: controlPassword } = useForm({});

  const validatePassword = (data) => {
    data.CPFCNPJ = localStorage.getItem('cpfcnpj');
    dispatch(
      renovationJwtAction(data, (res) => {
        res && close();
      }),
    );
  };

  return (
    <>
      <Modal open={open} width={'31%'} close={close}>
        <Styled.SmallerModal>
          <MdErrorOutline color={theme.colors.nutBrown} size={'6.944vw'} />
          <h3>Inserir Senha</h3>
          <label>Sua sessão será encerrada em {time}.</label>
          <label>Digite sua senha para permanecer logado.</label>
          <h4>Senha</h4>
          <form onSubmit={handleUserPassword(validatePassword)}>
            <TextField
              name={'SENHA'}
              variant={'outlined'}
              width={'100%'}
              control={controlPassword}
              required={true}
              type={'password'}
              inputProps={{ maxLength: 32 }}
            />
            <Styled.ButtonContainer>
              <Button
                backgroundcolor={theme.colors.buttonBack}
                width={'13.792vw'}
                height={'3.125vw'}
                hoverbgcolor={theme.colors.buttonBackHover}
                hovercolor={theme.colors.buttonBackHoverText}
                mycolor={theme.colors.buttonBackText}
                onClick={() => close()}
              >
                <span>Voltar</span>
              </Button>
              <Button
                backgroundcolor={theme.colors.buttonConfirm}
                width={'13.792vw'}
                height={'3.125vw'}
                hoverbgcolor={theme.colors.buttonConfirmHover}
                hovercolor={theme.colors.buttonConfirmHoverText}
                mycolor={theme.colors.buttonConfirmText}
                type="submit"
              >
                <span>Confirmar</span>
              </Button>
            </Styled.ButtonContainer>
          </form>
        </Styled.SmallerModal>
      </Modal>
    </>
  );
};

export default RenovacaoJwt;
