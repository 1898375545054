import React, { useEffect, useState } from 'react';
import Wrapper from '../../components/Wrapper/Wrapper';
import Tabs from '../../components/Tabs/Tabs';
import PixInfractions from './tabContent/PixInfractions';
import { useDispatch } from 'react-redux';
import PixRefund from './tabContent/PixRefund';
import PixAntiFraud from './tabContent/PixAntiFraud';
import { pixRefundFetchActions } from '../../store/fetchActions/pixRefundActions';
import { pixInfractionFetchActions } from '../../store/fetchActions/pixInfractionActions';
import { pixAntiFraudFetchActions } from '../../store/fetchActions/pixAntiFraudActions';

export default function AdminPix() {
  const dispatch = useDispatch();

  const [tabIndex, setTabIndex] = useState(0);
  const labels = ['Devolução', 'Infração', 'Anti Fraude'];

  useEffect(() => {
    if (tabIndex === 0) dispatch(pixRefundFetchActions());
    if (tabIndex === 1) dispatch(pixInfractionFetchActions());
    if (tabIndex === 2) dispatch(pixAntiFraudFetchActions());
  }, [tabIndex]);

  return (
    <Wrapper title="Pix - Administração">
      <Tabs tabIndex={tabIndex} setTabIndex={setTabIndex} labels={labels} variant="fullWidth" margin="1.5vw 0" />
      {(() => {
        switch (tabIndex) {
          case 0:
            return <PixRefund value={tabIndex} index={0} />;
          case 1:
            return <PixInfractions value={tabIndex} index={1} />;
          case 2:
            return <PixAntiFraud value={tabIndex} index={1} />;
        }
      })()}
    </Wrapper>
  );
}
