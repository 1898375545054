import { memo, useEffect, useState } from 'react';
import Table from '../../../../components/Table/Table';
import { accountsDeniedFetchAction } from '../../../../store/fetchActions/userAccounts';
import { useDispatch, useSelector } from 'react-redux';
import Filter from '../../../../components/Filter/Filter';
import TextField from '../../../../components/TextField';
import DateInput from '../../../../components/DateInput';
import Button from '../../../../components/Button/Button';
import { ImSearch } from 'react-icons/im';
import formatCPF from '../../../../utils/functions/formatCPF';
import formatCNPJ from '../../../../utils/functions/formatCNPJ';
import { useForm } from 'react-hook-form';
import { FirstFilterLine, SecondFilterLine } from '../../../../components/Filter/Filter.styles';
import SelectOptions from '../../../../components/SelectOptions';
import useCallFilter from '../../../../utils/hooks/useCallFilter';
import * as dayjs from 'dayjs';
import { useTheme } from 'styled-components';
import moment from 'moment';
import Loading from '../../../../components/Loading';
import { pagesOptions } from '../../../../utils/constant';

function UsuariosNegados({ value, index }) {
  const theme = useTheme();
  const dispatch = useDispatch();

  const [filterData, setFilterData] = useState('');
  const [filter, setFilter] = useState({
    SEARCH: '',
    DATA_INICIAL: '',
    DATA_FINAL: '',
    LIMIT: 10,
    PAGE: 1,
  });
  const [dateInicial, setDateInicial] = useState(null);
  const [dateFinal, setDateFinal] = useState(moment().format('YYYY-MM-DD'));

  const { handleSubmit, control, reset } = useForm({});

  const {
    deniedAccountsData,
    paginationDeniedAccounts,
    loadingDeniedAccounts,
    errorDeniedAccounts,
    errorMessageDeniedAccounts,
  } = useSelector((state) => state.userAccounts);

  useCallFilter(filterData, setFilter, accountsDeniedFetchAction, value, index);

  useEffect(() => {
    dispatch(accountsDeniedFetchAction(filter));
  }, [filter.PAGE]);

  const header = [
    {
      id: 'data de registro',
      name: 'DATA DE REGISTRO',
      align: 'center',
    },
    {
      id: 'CPFCNPJ',
      name: 'CPF/CNPJ',
      align: 'left',
    },
    {
      id: 'nome',
      name: 'NOME',
      align: 'left',
    },
    {
      id: 'observação',
      name: 'OBSERVAÇÃO',
      align: 'left',
    },
  ];

  const body = deniedAccountsData?.map((val) => {
    return {
      data: [
        {
          info: dayjs(val.DT_INSERT).format('DD/MM/YYYY HH:mm:ss') || '-----',
          align: 'center',
        },
        {
          info: val.CPFCNPJ?.length === 11 ? formatCPF(val.CPFCNPJ) : formatCNPJ(val.CPFCNPJ),
          align: 'left',
        },
        { info: val.NOME, align: 'left' },
        { info: val.OBSERVACOES, align: 'left', width: '40vw' },
      ],
    };
  });

  const filterTable = (data) => {
    data.DATA_INICIAL = dateInicial;
    data.DATA_FINAL = dateFinal;
    setFilterData(data);
  };

  return (
    <div>
      <Filter>
        <form onSubmit={handleSubmit(filterTable)}>
          <FirstFilterLine>
            <TextField label="Nome, CPF/CNPJ ou Conta" name={'SEARCH'} width={'38.042vw'} control={control} />
            <DateInput
              label="Data Inicial"
              name={'DATA_INICIAL'}
              control={control}
              value={dateInicial}
              onChange={(newValue) =>
                newValue?.$d ? setDateInicial(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateInicial(null)
              }
            />
            <DateInput
              label="Data Final"
              name={'DATA_FINAL'}
              control={control}
              value={dateFinal}
              onChange={(newValue) =>
                newValue?.$d ? setDateFinal(moment(newValue?.$d).format('YYYY-MM-DD')) : setDateFinal(null)
              }
            />

            <Button
              backgroundcolor={theme.colors.buttonSearch}
              width="12.153vw"
              margin={'0 1.389vw 0 0'}
              hoverbgcolor={theme.colors.buttonSearchHover}
              hovercolor={theme.colors.buttonSearchHoverText}
              mycolor={theme.colors.buttonSearchText}
              type="submit"
            >
              <ImSearch className="default-svg" size={'1.181vw'} />
              <span>Buscar</span>
            </Button>
          </FirstFilterLine>

          <SecondFilterLine>
            <h5>Resultados por página</h5>
            <SelectOptions control={control} options={pagesOptions} name={'LIMIT'} initialValue={10} />
            <h5>Total de resultados: {paginationDeniedAccounts?.totalResult}</h5>
          </SecondFilterLine>
        </form>
      </Filter>

      {loadingDeniedAccounts ? (
        <Loading />
      ) : (
        <Table
          loading={loadingDeniedAccounts}
          error={errorDeniedAccounts}
          hasPagination={true}
          filter={filter}
          pagination={paginationDeniedAccounts}
          setFilter={setFilter}
          errorMessage={errorMessageDeniedAccounts}
          filterTable={filterTable}
          header={header}
          body={body}
        />
      )}
    </div>
  );
}

export default memo(UsuariosNegados);
