import api from '../../utils/services/api';

import { pixRefundActionLoading, pixRefundActionSuccess, pixRefundActionError } from '../ducks/pixRefund';

export const pixRefundFetchActions = (filter = { limit: 10, page: 1 }) => {
  return async (dispatch) => {
    dispatch(pixRefundActionLoading());

    try {
      const response = await api.get(`admin/pix/listar/devolucoes`, filter);
      dispatch(pixRefundActionSuccess(response));
    } catch (error) {
      dispatch(pixRefundActionError(error?.response?.data));
    }
  };
};


export const pixRefundTypeParticipantsFetchActions = () => {
  return async (dispatch) => {
    dispatch(pixRefundActionLoading());

    try {
      const response = await api.get(`admin/pix/papel/participante`);
      dispatch(pixRefundActionSuccess(response));
    } catch (error) {
      dispatch(pixRefundActionError(error?.response?.data));
    }
  };
};

export const pixRefundTypeStatusFetchActions = () => {
  return async (dispatch) => {
    dispatch(pixRefundActionLoading());

    try {
      const response = await api.get(`admin/pix/status/devolucao`);
      dispatch(pixRefundActionSuccess(response));
    } catch (error) {
      dispatch(pixRefundActionError(error?.response?.data));
    }
  };
};