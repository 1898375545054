import { createAction, createReducer } from '@reduxjs/toolkit';

const INITIAL_STATE = {
  dataPixAntiFraud: [],
  errorPixAntiFraud: false,
  errorLoadingPixAntiFraud: false,
  errorMessagePixAntiFraud: '',
  loadingPixAntiFraud: false,
  paginationPixAntiFraud: {},
  detailsPixAntiFraud: {},
  loadingDetailsPixAntiFraud: false,
  messageDetailsPixAntiFraud: '',
  successDetailsPixAntiFraud: false,
};

export const pixAntiFraudActionLoading = createAction('PIX_ANTIFRAUD_ACTION_LOADING');
export const pixAntiFraudActionSuccess = createAction('PIX_ANTIFRAUD_ACTION_SUCCESS');
export const pixAntiFraudActionError = createAction('PIX_ANTIFRAUD_ACTION_ERROR');

export const detailsPixAntiFraudActionLoading = createAction('DETAILS_PIX_ANTIFRAUD_ACTION_LOADING');
export const detailsPixAntiFraudActionSuccess = createAction('DETAILS_PIX_ANTIFRAUD_ACTION_SUCCESS');
export const detailsPixAntiFraudActionError = createAction('DETAILS_PIX_ANTIFRAUD_ACTION_ERROR');

export default createReducer(INITIAL_STATE, {
  [pixAntiFraudActionLoading.type]: (state) => {
    return {
      ...state,
      loadingPixAntiFraud: true,
      errorPixAntiFraud: false,
    };
  },
  [pixAntiFraudActionSuccess.type]: (state, payload) => {
    const { data, pagination } = payload?.payload?.data?.data;

    return {
      ...state,
      loadingPixAntiFraud: false,
      errorPixAntiFraud: false,
      dataPixAntiFraud: data,
      paginationPixAntiFraud: pagination,
    };
  },
  [pixAntiFraudActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingPixAntiFraud: false,
      errorPixAntiFraud: true,
      errorMessagePixAntiFraud: payload?.mensagem,
      dataPixAntiFraud: [],
      paginationPixAntiFraud: {},
    };
  },
  [detailsPixAntiFraudActionLoading.type]: (state) => {
      return {
        ...state,
        loadingDetailsPixAntiFraud: true,
      };
    },
  [detailsPixAntiFraudActionSuccess.type]: (state, payload) => {
    const { data, mensagem } = payload?.payload?.data;

    return {
      ...state,
      loadingDetailsPixAntiFraud: false,
      detailsPixAntiFraud: data,
      messageDetailsPixAntiFraud: mensagem,
      successDetailsPixAntiFraud: true,
    };
  },
  [detailsPixAntiFraudActionError.type]: (state, payload) => {
    return {
      ...state,
      loadingDetailsPixAntiFraud: false,
      detailsPixAntiFraud: [],
      messageDetailsPixAntiFraud: payload?.mensagem,
      successDetailsPixAntiFraud: false,
    };
  },
});
