import PropTypes from 'prop-types';
import React, { useState } from 'react';
import ModalTwoFactor from '../../../components/TwoFactor/ModalTwoFactor';
import * as Styled from './Information.styles';

import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { useTheme } from 'styled-components';
import DropDown from '../../../components/DropDown/DropDown';
import {
  pixRefundAproveFetchAction,
  pixRefundNegadedFetchAction,
  pixTransactionAproveCelcoinFetchAction,
  pixTransactionAproveFetchAction,
  pixTransactionNegadedFetchAction,
} from '../../../store/fetchActions/pixTransactionsActions';

export default function InformationPix({ title, data, color, bold, idTransaction, showButton, onClose, refund }) {
  const theme = useTheme();

  const dispatch = useDispatch();

  const [expanded, setExpanded] = useState('');
  const [disableButon, setDisableButon] = useState(false);
  const [hoverColorDeni, setHoverColorDeni] = useState(theme.colors.buttonNegadedText);
  const [token, setToken] = useState('');
  const [showTwoFactorModal, setShowTwoFactorModal] = useState(false);
  const [isAprove, setIsAprove] = useState(false);
  const [isAproveRefund, setIsAproveRefund] = useState(false);
  const [isApproveCelcoin, setIsAproveCelcoin] = useState(false);

  const { handleSubmit: handleToken, control: tokenControl } = useForm({});

  const handleDataForm = (data) => {
    setShowTwoFactorModal(false);

    if (isAprove) return aproved(data);

    isApproveCelcoin ? aprovedCelcoin(data) : deny(data);
  };

  const handleRefundDataForm = (data) => {
    setShowTwoFactorModal(false);

    if (isAproveRefund) return aprovedRefund(data);
    if (!isAproveRefund) return denyRefund(data);
  };

  const deny = (data) => {
    dispatch(
      pixTransactionNegadedFetchAction(
        {
          ID_TRANSACAO: idTransaction,
          TOKEN: data,
        },
        onClose(),
      ),
    );
  };

  const aproved = (data) => {
    setDisableButon(true);
    dispatch(
      pixTransactionAproveFetchAction(
        {
          ID_TRANSACAO: idTransaction,
          TOKEN: data,
        },
        onClose(),
      ),
    );
  };

  const aprovedCelcoin = (data) => {
    setDisableButon(true);
    dispatch(
      pixTransactionAproveCelcoinFetchAction(
        {
          ID_TRANSACAO: idTransaction,
          TOKEN: data,
        },
        onClose(),
      ),
    );
  };

  const denyRefund = (data) => {
    dispatch(
      pixRefundNegadedFetchAction(
        {
          ID_TRANSACAO: idTransaction,
          TOKEN: data,
        },
        onClose(),
      ),
    );
  };

  const aprovedRefund = (data) => {
    setDisableButon(true);
    dispatch(
      pixRefundAproveFetchAction(
        {
          ID_TRANSACAO: idTransaction,
          TOKEN: data,
        },
        onClose(),
      ),
    );
  };

  const verifyAction = (action) => {
    if (action === 'approveStatus') {
      setShowTwoFactorModal(true);
      setIsAproveCelcoin(true);
      return;
    } else if (action === 'deny') {
      setShowTwoFactorModal(true);
      setIsAprove(false);
      return;
    }
    setShowTwoFactorModal(true);
    setIsAprove(true);
  };

  const verifyActionRefund = (action) => {
    if (action === 'approve') {
      setShowTwoFactorModal(true);
      setIsAproveRefund(true);
      return;
    } else if (action === 'deny') {
      setShowTwoFactorModal(true);
      setIsAproveRefund(false);
      return;
    }
  };

  return (
    <Styled.ContainerStyled>
      <Styled.TitleStyled>{title}</Styled.TitleStyled>
      {typeof data === 'string' ? (
        <Styled.ParagraphName>{data}</Styled.ParagraphName>
      ) : (
        <>
          {data?.map((data, index) => {
            return (
              <Styled.RowContainer key={index} role={'information-row'}>
                <Styled.ParagraphName>{data.name}</Styled.ParagraphName>
                <Styled.ParagraphValue color={color} bold={bold}>
                  {data.value}
                </Styled.ParagraphValue>
              </Styled.RowContainer>
            );
          })}
          {showButton && !refund && (
            <Styled.EditButtonContainer>
              <DropDown
                openModalPassword={() => setExpanded(true)}
                name="Ações"
                options={[
                  { label: 'Aprovar', action: 'approve' },
                  { label: 'Negar', action: 'deny' },
                  { label: 'Aprovar status', action: 'approveStatus' },
                ]}
                setNameSelectedItem={(action) => verifyAction(action)}
              />
            </Styled.EditButtonContainer>
          )}
          {showButton && refund && (
            <Styled.EditButtonContainer>
              <DropDown
                openModalPassword={() => setExpanded(true)}
                name="Ações"
                options={[
                  { label: 'Aprovar', action: 'approve' },
                  { label: 'Negar', action: 'deny' },
                ]}
                setNameSelectedItem={(action) => verifyActionRefund(action)}
              />
            </Styled.EditButtonContainer>
          )}
        </>
      )}
      {showTwoFactorModal && !refund && (
        <ModalTwoFactor
          open={showTwoFactorModal}
          onClose={() => setShowTwoFactorModal(false)}
          handleToken={handleToken}
          onSubmit={(data) => handleDataForm(data)}
          control={tokenControl}
        />
      )}
      {showTwoFactorModal && refund && (
        <ModalTwoFactor
          open={showTwoFactorModal}
          onClose={() => setShowTwoFactorModal(false)}
          handleToken={handleToken}
          onSubmit={(data) => handleRefundDataForm(data)}
          control={tokenControl}
        />
      )}
    </Styled.ContainerStyled>
  );
}

InformationPix.propTypes = {
  title: PropTypes.string,
  data: PropTypes.array,
  color: PropTypes.string,
  bold: PropTypes.bool,
  children: PropTypes.node,
  idTransaction: PropTypes.string,
};
